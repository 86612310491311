import providerChannelModule from '@/services/providerChannel';

export default {
  name: 'whatsAppTemplateSelectorPopup',
  props: {
    channel: {
      type: String,
      requried: true
    }
  },
  components: {},
  data() {
    return {
      visible: false,
      loading: false,
      fetchMoreLoader: false,

      providerId: null,
      templateList: null,

      allowPaginaton: false,
      offset: 0,
      limit: 50,
      showLoadMore: true,

      allowSearch: false,
      searchTerm: ''
    };
  },

  methods: {
    showDialog(providerId) {
      this.providerId = providerId;

      this.offset = 0;
      this.searchTerm = '';

      this.fetchTemplates();
      this.visible = true;
    },

    async fetchTemplates(isLoadMore) {
      try {
        if (!isLoadMore) {
          this.loading = true;
        } else {
          this.fetchMoreLoader = true;
        }

        let params = {
          id: this.providerId,
          offset: this.offset,
          limit: this.limit,
          searchTerm: this.allowSearch ? this.searchTerm.trim() : null
        };
        console.log('PARAMS:', params);
        let response = await providerChannelModule.getWhatsAppTemplateListV2(params);
        console.log('RESPONSE:', response);
        if (!isLoadMore) {
          this.templateList = response.data.templates;
        } else {
          this.templateList = response.data.templates ? this.templateList.concat(response.data.templates) : this.templateList;
        }
        this.allowSearch = response.data.searchable;
        this.allowPaginaton = response.data.pagination;

        // Show Hide Load More.
        if (response.data.templates.length > 0) this.showLoadMore = true;
        else this.showLoadMore = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      } finally {
        this.loading = false;
        this.fetchMoreLoader = false;
      }
    },

    async onLoadMoreTemplateButtonClick() {
      this.offset += this.limit;
      await this.fetchTemplates(true);
    },

    async onSearch() {
      this.offset = 0;
      await this.fetchTemplates();
    },

    async onTemplateSelect(template) {
      console.log('Template selected', template);
      this.$emit('onTemplateSelect', template);
      this.visible = false;
    }
  },

  mounted() {}
};
